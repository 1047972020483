// assets
import { IconPalette, IconShadow, IconTypography, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: '',
    type: 'group',
    children: [
        {
            id: 'master',
            title: 'Master',
            type: 'collapse',
            icon: icons.IconWindmill,
            children: [
                {
                    id: 'state',
                    title: 'State',
                    type: 'item',
                    url: '/master/state',
                    breadcrumbs: false
                },
                {
                    id: 'city',
                    title: 'City',
                    type: 'item',
                    url: '/master/city',
                    breadcrumbs: false
                },
                {
                    id: 'Depo',
                    title: 'Depo',
                    type: 'item',
                    url: '/master/depo',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'operator',
            title: 'Operator',
            type: 'item',
            icon: icons.IconWindmill,
            url: '/operator',
            breadcrumbs: false
        },
        {
            id: 'transport',
            title: 'Transport Agency',
            type: 'item',
            icon: icons.IconWindmill,
            url: '/transportAgency',
            breadcrumbs: false
        },
        {
            id: 'operatortripsheet',
            title: 'Operator Trip Sheet',
            type: 'item',
            icon: icons.IconWindmill,
            url: '/viewoperatordata',
            breadcrumbs: false
        },
        {
            id: 'transporttripsheet',
            title: 'Transport Trip Sheet',
            type: 'item',
            icon: icons.IconWindmill,
            url: '/viewtransportdata',
            breadcrumbs: false
        },
        {
            id: 'freeze',
            title: 'Logs',
            type: 'item',
            icon: icons.IconWindmill,
            url: '/logs',
            breadcrumbs: false
        }
    ]
};

export default utilities;
