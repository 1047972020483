import { Navigate } from 'react-router-dom';
import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import PrivateRoute from './PrivateRoute';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const AddState = Loadable(lazy(() => import('views/master/States/index')));
const AddCity = Loadable(lazy(() => import('views/master/city/index')));
const ViewOperator = Loadable(lazy(() => import('views/master/operator/index')));
const ViewFreeze = Loadable(lazy(() => import('views/master/logsdata/index')));
const ViewOperatorData = Loadable(lazy(() => import('views/master/viewoperatordata/index')));
const ViewTransportData = Loadable(lazy(() => import('views/master/viewtransportdata/index')));
const ViewTransport = Loadable(lazy(() => import('views/master/transportAgency/index')));
const Tournament = Loadable(lazy(() => import('views/master/tournament/index')));
const Game = Loadable(lazy(() => import('views/master/Game/index')));
const EditTournament = Loadable(lazy(() => import('views/master/tournament/EditTournament')));
const ViewInfo = Loadable(lazy(() => import('views/master/tournament/ViewInfo')));
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));

const MainRoutes = {
    path: '/',
    exact: true,
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Navigate to="superadmin/login" />
        },
        {
            path: '/dashboard/default',
            element: <PrivateRoute element={DashboardDefault} />
        },
        {
            path: '/master/state',
            element: <PrivateRoute element={AddState} />
        },
        {
            path: '/master/city',
            element: <PrivateRoute element={AddCity} />
        },
        {
            path: '/operator',
            element: <PrivateRoute element={ViewOperator} />
        },
        {
            path: '/logs',
            element: <PrivateRoute element={ViewFreeze} />
        },
        {
            path: '/viewoperatordata',
            element: <PrivateRoute element={ViewOperatorData} />
        },
        {
            path: '/viewtransportdata',
            element: <PrivateRoute element={ViewTransportData} />
        },
        {
            path: '/transportAgency',
            element: <PrivateRoute element={ViewTransport} />
        },
        {
            path: '/master/depo',
            element: <PrivateRoute element={Game} />
        },
        {
            path: '/master/tournaments',
            element: <PrivateRoute element={Tournament} />
        },
        {
            path: '/master/tournaments/edit/:id',
            element: <PrivateRoute element={EditTournament} />
        },
        {
            path: '/master/tournaments/viewInfo/:id',
            element: <PrivateRoute element={ViewInfo} />
        }
    ]
};

export default MainRoutes;
