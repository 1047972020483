import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";
import axios from "axios";
import config from "../../../../config";

const ChangeSuperAdminPassword = ({ open, handleClose, setIsUpdateTable }) => {
  const token = localStorage.getItem("accessToken");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChangePassword = async () => {
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }
    try {
      const response = await axios.patch(
        `${config.baseUrl}/auth/chnage-password`,
        { password: password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status) {
        handleClose();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value !== password) {
      setErrorMessage("Passwords do not match");
    } else {
      setErrorMessage("");
    }
  };

  return (
    <Modal 
      open={open} 
      onClose={handleClose}
      BackdropProps={{ onClick: handleClose }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh"
        }}
      >
        <div
          onClick={(e) => e.stopPropagation()} 
          style={{
            padding: 20,
            background: "#fff",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            borderRadius: 8,
            maxWidth: 400,
          }}
        >
          <h2 style={{ color: "#673ab7" }}>Change Password</h2>
          <TextField
            label="New Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errorMessage && (
            <Typography color="error" variant="body2">
              {errorMessage}
            </Typography>
          )}
          <Button
            onClick={handleChangePassword}
            variant="contained"
            style={{ backgroundColor: "#673ab7", color: "#fff" }}
          >
            Change Password
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeSuperAdminPassword;
