import { useState, useRef, useEffect } from "react";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import config from "../../../../config";
import ChangeSuperAdminPassword from "./ChangeSuperAdminPassword";
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Checkbox,
  ClickAwayListener,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Paper,
  Popper,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import User1 from "assets/images/users/user-round.svg";

// assets
import { IconSettings, IconLogout } from "@tabler/icons";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");
  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState("");
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [matchDataOpen, setMatchDataOpen] = useState(false);
  const [matchDataList, setMatchDataList] = useState([]);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/superadmin/login";
  };
  const handleMatchData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${config.baseUrl}/admin/fields`, {
        headers,
      });
      if (response.data.status && response.data.data.length > 0) {
        const fields = response.data.data[0].fields;
        setMatchDataList(fields);
        setMatchDataOpen(true);
      } else {
        console.error("Failed to fetch match data:", response.data.message);
        swal(
          "Error",
          "An error occurred while matching data. Please try again later.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error occurred while matching data:", error);
      swal(
        "Error",
        "An error occurred while matching data. Please try again later.",
        "error"
      );
    }
  };

  const handleCheckboxChange = (index) => {
    const updatedMatchDataList = matchDataList.map((field, i) =>
      i === index ? { ...field, status: !field.status } : field
    );
    setMatchDataList(updatedMatchDataList);
  };

  const handleSave = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = {
        fields: matchDataList,
      };
      const response = await axios.patch(
        `${config.baseUrl}/admin/fields/6651b347a28fbdc9b37a2ef2`,
        data,
        { headers }
      );
      if (response.data.status) {
        swal("Success", "Data updated successfully.", "success");
        setMatchDataOpen(false);
      } else {
        console.error("Failed to update match data:", response.data.message);
        swal(
          "Error",
          "An error occurred while updating data. Please try again later.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error occurred while updating match data:", error);
      swal(
        "Error",
        "An error occurred while updating data. Please try again later.",
        "error"
      );
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const formatFieldValue = (value) =>
    value
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={User1}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            color={theme.palette.primary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">Hi,</Typography>
                      </Stack>
                      <Typography variant="subtitle2">Super Admin</Typography>
                    </Stack>
                  </Box>
                  <PerfectScrollbar
                    style={{
                      height: "100%",
                      maxHeight: "calc(100vh - 250px)",
                      overflowX: "hidden",
                    }}
                  >
                    <Box sx={{ p: 2 }}>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 350,
                          minWidth: 300,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                        <ListItemButton>
                          <Button
                            onClick={handleMatchData}
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{ mr: 2 }}
                          >
                            Match Data
                          </Button>
                        </ListItemButton>
                        <ListItemButton>
                          {" "}
                          <Button
                            onClick={() => setPasswordModalOpen(true)}
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{ mr: 2 }}
                          >
                            {" "}
                            Change Password{" "}
                          </Button>{" "}
                        </ListItemButton>
                        <ListItemButton>
                          <Button
                            onClick={handleLogout}
                            variant="contained"
                            color="secondary"
                            size="small"
                            startIcon={<IconLogout />}
                          >
                            {" "}
                            Logout
                          </Button>
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      <Dialog open={matchDataOpen} onClose={() => setMatchDataOpen(false)}>
        <DialogTitle>Matching Data Fields</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {matchDataList.map((field, index) => (
              <Grid item xs={6} key={index}>
                <ListItem>
                  <Checkbox
                    checked={field.status}
                    onChange={() => handleCheckboxChange(index)}
                  />
                  <ListItemText primary={formatFieldValue(field.value)} />
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMatchDataOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ChangeSuperAdminPassword
        open={passwordModalOpen}
        handleClose={() => setPasswordModalOpen(false)}
      />
    </>
  );
};

export default ProfileSection;
